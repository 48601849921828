import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import { DashboardList } from '@app/store/actions/website-action/dashboard.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

interface IDashboardStateModel {
  dashboardList: IDashboardList | null;
  dashboardListCount: number;
}
@State<IDashboardStateModel>({
  name: 'DashboardState',
  defaults: {
    dashboardList: null,
    dashboardListCount: 0,
  },
})
@Injectable()
export class DashboardState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static dashboardList(state: IDashboardStateModel) {
    return state.dashboardList;
  }

  @Action(DashboardList)
  DashboardList(
    ctx: StateContext<IDashboardStateModel>,
    { param }: DashboardList
  ) {
    return this._http.post('dashboard/list', param).pipe(
      tap((apiResult) => {
        const apiResonse: IDashboardList = apiResult.response.dataset;
        apiResonse.websites = apiResonse.websites.map((item) => {
          let upc = item.milestones.filter((m) => m.status == 1);
          let inp = item.milestones.filter((m) => m.status == 2);
          // let firstInp = inp[0]
          let comp = item.milestones.filter((m) => m.status == 3);
          
         if(inp.length) {(inp.sort((a, b) => {
      // Compare year, then month, then day
      const yearDiff = new Date(a.updated_date).getFullYear() - new Date(b.updated_date).getFullYear();
      if (yearDiff !== 0) return yearDiff;

      const monthDiff = new Date(a.updated_date).getMonth() - new Date(b.updated_date).getMonth();
      if (monthDiff !== 0) return monthDiff;

      return new Date(a.updated_date).getDate() - new Date(b.updated_date).getDate();
    })
  )}
  if(comp.length) {(comp.sort((a, b) => {
      // Compare year, then month, then day
      const yearDiff = new Date(a.updated_date).getFullYear() - new Date(b.updated_date).getFullYear();
      if (yearDiff !== 0) return yearDiff;

      const monthDiff = new Date(a.updated_date).getMonth() - new Date(b.updated_date).getMonth();
      if (monthDiff !== 0) return monthDiff;

      return new Date(a.updated_date).getDate() - new Date(b.updated_date).getDate();
    })
  )}
  
          return { ...item, inprogress: inp, complete: comp, upcoming: upc };
        });
        ctx.patchState({
          dashboardList: apiResonse,
          dashboardListCount: apiResult.response.dataset.total_rows,
        });
      })
    );
  }
}
